import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import cx from 'classnames'

interface FormNavButtonProps<TargetId extends string = string> {
  type: 'Previous' | 'Next'
  targetId: TargetId
}

const FormNavButton: React.FC<FormNavButtonProps> = ({ type, targetId }) => (
  <Link
    to={`#${targetId}`}
    className={cx(
      'text-zinc-700 block rounded-md bg-transparent px-4 pb-1 pt-1.5 font-display text-sm outline outline-2 outline-transparent transition-all duration-200',
      'hover:outline-zinc-700 hover:bg-white hover:bg-opacity-10'
    )}>
    <div className="flex items-center space-x-1">
      <span>{type}</span>
      {type === 'Previous' ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
    </div>
  </Link>
)

interface FormSectionWrapperProps<Order extends string[] = string[]> {
  id: Order[number]
  order: Order
  children: React.ReactNode
}

// NOTE: I don't think this component is still used
export const FormScreen: React.FC<FormSectionWrapperProps> = ({ id, children, order }) => {
  const position = order.findIndex(item => item === id)
  const prev = order[position - 1]
  const next = order[position + 1]

  return (
    <div className="flex min-h-screen items-center justify-center p-4 pb-12 pt-8" id={id}>
      {(prev || next) && (
        <div className="absolute -top-12 right-0 mt-2 flex space-x-2">
          {prev && <FormNavButton targetId={prev} type="Previous" />}
          {next && <FormNavButton targetId={next} type="Next" />}
        </div>
      )}
      <div
        className={cx(
          'flex w-full max-w-lg flex-col space-y-6 rounded-lg bg-white dark:bg-dark dark:text-white',
          'p-8'
        )}>
        {children}
        {next && (
          <div className="mx-auto w-full max-w-xs px-8 py-1 sm:pt-8">
            <Link
              to={`#${next}`}
              className={cx(
                'text-md block w-full rounded-md px-6 pb-2 pt-3 text-center font-display outline outline-2 outline-offset-0 outline-grey transition-all duration-200',
                'hover:shadow-lg hover:outline-offset-0 hover:outline-black'
              )}>
              Next
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
