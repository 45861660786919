import React from 'react'
import * as RG from '@radix-ui/react-radio-group'
import cx from 'classnames'
import useMediaQuery from '~/hooks/useMediaQuery'
import FeatureBullets from './FeatureBullets'

interface RadioCardProps extends RG.RadioGroupItemProps {
  tagLine: string
  header: string
  bullets: string[]
  id: string
  isMobile?: boolean
}

const RadioCard: React.FC<RadioCardProps> = ({ tagLine, header, bullets, id, isMobile, ...props }) => (
  <RG.Item
    {...props}
    className="h-full cursor-pointer rounded-xl text-left text-mid outline outline-2 outline-grey focus-visible:outline-offset-0 dark:text-light dark:outline-light"
    id={id}>
    <label className="flex h-full flex-col p-4" htmlFor={id}>
      <span className="font-copy text-xs uppercase">{tagLine}</span>
      <span className="mt-2 text-2xl">{header}</span>
      <FeatureBullets bullets={bullets} />
    </label>
  </RG.Item>
)

interface RadioCardsProps extends RG.RadioGroupProps, React.RefAttributes<HTMLDivElement> {
  commonBullets?: string[]
  name: string
}

const RadioCards: React.FC<RadioCardsProps> & { Card: typeof RadioCard } = ({
  children,
  commonBullets,
  name,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)')

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement<RadioCardProps>(child) && child.type === RadioCard) {
      return React.cloneElement(child, { isMobile })
    }
    return null
  })

  return (
    <div className="space-y-4">
      <RG.Root {...props} name={name} className={cx('radio-cards grid w-full gap-4', 'grid-cols-1', 'sm:grid-cols-2')}>
        {childrenWithProps}
      </RG.Root>
      {commonBullets?.length ? (
        <div className="space-y-2">
          <h6 className="mb-0">Both include:</h6>
          <FeatureBullets bullets={commonBullets} />
        </div>
      ) : null}
    </div>
  )
}

RadioCards.Card = RadioCard

export default RadioCards
