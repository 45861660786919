import { Form, useActionData, useNavigation } from '@remix-run/react'

import Button from '~/components/Button'
import { FormScreen } from '~/components/forms/FormScreen'
import FullScreenWrapper from '~/modules/layout/components/FullScreenWrapper'
import RadioCards from '~/modules/signup/components/RadioCard'
import useMediaQuery from '~/hooks/useMediaQuery'
import type { action } from './action'

export const getBullets = (includeCommon: boolean, isYearly: boolean) =>
  [
    ...(includeCommon
      ? [
          '7 day free trial',
          'Access to our full library of educational videos',
          'Access to our printable resources',
          'Subscription to our monthly newsletter'
        ]
      : []),
    isYearly && 'Discounted price for annual subscribers'
  ].filter(Boolean) as string[]

export const SignUpStep1SelectPlanPage = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const errors = useActionData<typeof action>()
  const navigation = useNavigation()
  const submitting = navigation.state === 'submitting'

  return (
    <FullScreenWrapper className="register-form">
      <FormScreen id="plan" order={['plan']}>
        <Form method="post" className="space-y-4">
          <h2 className="border-b-4 border-b-grey pb-2 dark:border-b-white">Choose plan</h2>
          <RadioCards name="frequency" commonBullets={getBullets(isMobile, false)} defaultValue="annual">
            <RadioCards.Card
              tagLine="Monthly subscription"
              header="$9.99 / month"
              bullets={getBullets(!isMobile, false)}
              id="monthly"
              value="monthly"
            />
            <RadioCards.Card
              tagLine="Yearly subscription"
              header="$99 / year"
              bullets={getBullets(!isMobile, true)}
              id="annual"
              value="annual"
            />
          </RadioCards>
          {errors?.frequency && <p className="text-pink">{errors?.frequency}</p>}
          {errors?.session && <p className="text-pink">{errors?.session}</p>}
          {errors?.email && <p className="text-pink">{errors?.email}</p>}
          <div className="mx-auto w-full max-w-xs px-8 py-1 sm:pt-8">
            <Button
              intent="success"
              size="lg"
              text="Next"
              className="w-full flex-1"
              isDisabled={submitting}
              isLoading={submitting}
            />
          </div>
        </Form>
      </FormScreen>
    </FullScreenWrapper>
  )
}

export default SignUpStep1SelectPlanPage
